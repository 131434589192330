export const COLORS = {
  primaryDarknest: '#004251',
  primaryDefault: '#2A7F89',
  primaryLight: '#4FA1A6',
  primaryLighter: '#58B3B8',
  primaryMuted: '#71B5BE',
  accentDefault: '#FB837A',
  accentDark: '#D7595A',
  shadesDarknest: '#394243',
  shadesDark: '#6F7676',
  shadesLight: '#C4C6C7',
  shadesBackground: '#F3F5FA',
  shadesShadow: '#616869',
  primaryWhite: '#FFFFFF',
  background: '#f3f5fa',
  honeydew: '#F5FFF3',
  green: '#079864',
  lavenderBlush: '#FFEDEC',
  alertRed: '#A90F1C',
  blanchedAlmond: '#FFECCF',
  yellowOrange: '#F4AF46',
};
